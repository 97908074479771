/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.3.1/dist/jquery.min.js
 * - /npm/blazy@1.8.2/blazy.min.js
 * - /npm/object-fit-images@3.2.3/dist/ofi.min.js
 * - /npm/formstone@1.4.6/src/js/core.min.js
 * - /npm/formstone@1.4.0/src/js/cookie.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
